import { Injectable } from "@angular/core";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { Observable, Subject } from "rxjs";
import { NotificationComponent } from "src/app/core/components/notification/notification.component";
export interface Notification {
  message: string;
  level: "Success" | "Error" | "Warning";
}

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private notification$: Subject<Notification> = new Subject();

  public get onNotification(): Observable<Notification> {
    return this.notification$;
  }

  constructor(private snackBar: MatSnackBar) {
    this.setUpNotificationRx();
  }

  sendNotification(notification: Notification): void {
    this.notification$.next(notification);
  }

  success(message: string): void {
    this.sendNotification({ message, level: "Success" });
  }

  error(message: string): void {
    this.sendNotification({ message, level: "Error" });
  }

  warning(message: string): void {
    this.sendNotification({ message, level: "Warning" });
  }

  private setUpNotificationRx(): void {
    this.onNotification.subscribe((notification) => {
      this.showNotification(notification);
    });
  }

  private showNotification(data: Notification): void {
    // this.snackBar.open(notification.message);
    this.snackBar.openFromComponent(NotificationComponent, {
      data,
      duration: 4000,
    });
  }
}
