<div [ngClass]="{'notification':true,
'success':data.level ==='Success',
'warning':data.level ==='Warning',
'error':data.level ==='Error'}">
    <div class="flex w-full items-center">
    <img *ngIf="data.level ==='Error'" src="assets/svg/error.svg" class="image" >
    <img *ngIf="data.level ==='Warning'" src="assets/svg/warning.svg" class="image" >
    <img *ngIf="data.level ==='Success'" src="assets/svg/success.svg" class="image" >

    <span class="center">{{data.message | translate}}</span>


    </div>
    <img (click)="close()"  src="assets/svg/close.svg" class="close" >
</div>
