import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { GetMasterdataElementNamePipe } from 'src/app/shared/pipes/get-masterdata-element-name/get-masterdata-element-name.pipe';
import { GetMasterDataPipe } from 'src/app/shared/pipes/get-masterdata/get-masterdata.pipe';
import { ProcessingStatus, Roles, VALIDATION_STATUS_ENUM, ValidationStatus } from 'src/app/shared/models/other-data.model';
import { MasterDataService } from 'src/app/shared/services/master-data/master-data.service';
import { MasterDataTypes } from 'src/app/shared/models/master-data.model';
import { UsersService } from 'src/app/shared/services/admin/users/users.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss']
})
export class HomeSearchComponent implements OnInit {
  @Output() formChanged = new EventEmitter();
  searchForm!: UntypedFormGroup;
  options!: any;
  @Input() reset;
  @Output() resetValues = new EventEmitter();
  @Input() expanded;
  user_id: string;
  userId: string;
  showValidatedOptions: boolean = false;
  user_interests: any

  constructor(private builder: UntypedFormBuilder,
    private translateService: TranslateService,
    private masterDataService: MasterDataService,
    private getMasterdataElementName: GetMasterdataElementNamePipe,
    private getMasterData: GetMasterDataPipe,
    private usersService: UsersService,
    private route: ActivatedRoute
  ) {

    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((params: ParamMap) => {
      const userName = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.aws.Auth.userPoolWebClientId}.LastAuthUser`)

    this.userId= JSON.parse(localStorage.getItem(`CognitoIdentityServiceProvider.${environment.aws.Auth.userPoolWebClientId}.${userName}.userData`)).UserAttributes.find(a => a.Name == "sub")?.Value
      this.getUserData()
    });

    this.buildSearchForm(builder);

    this.options = {
      lang: [],
      validated: [],
      areas: [],
      fields: [],
      initiatives: [],
      clusters: [],
      issuers: [],
      phases: [],
      processing_status: []
    };
  }

  buildSearchForm(builder) {
    let _lastSearchQuery = JSON.parse(localStorage.getItem('lastSearchQuery'));

    let lastValidatedValue = '';
    if (_lastSearchQuery?.validated === true) {
      lastValidatedValue = VALIDATION_STATUS_ENUM.VALIDATED;
    } else if (_lastSearchQuery?.validated === false) {
      lastValidatedValue = VALIDATION_STATUS_ENUM.PENDING;
    }

    let lastInitiativesValue = this.refreshTransformMasterDataValues(_lastSearchQuery?.initiatives, MasterDataTypes.INITIATIVES);
    let lastIssuersValue = this.refreshTransformMasterDataValuesByFilter(_lastSearchQuery, MasterDataTypes.ISSUER);
    let lastAreasValue = this.refreshTransformMasterDataValues(_lastSearchQuery?.areas, MasterDataTypes.AREA);
    let lastFieldsValue = this.refreshTransformMasterDataFields(_lastSearchQuery?.fields, MasterDataTypes.FIELD);

    let lastStartDateValue = '';
    let lastEndDateValue = '';
    if (_lastSearchQuery && _lastSearchQuery?.publication_date && Object.values(_lastSearchQuery?.publication_date).flat().length >= 0) {
      lastStartDateValue = _lastSearchQuery?.publication_date[0];
      lastEndDateValue = _lastSearchQuery?.publication_date[1];
    }

    this.searchForm = this.builder.group({
      title: (_lastSearchQuery?.title) ? _lastSearchQuery?.title : [''],
      lang: (_lastSearchQuery?.lang) ? _lastSearchQuery?.lang : [''],
      validated: [lastValidatedValue], // text status ex: validated
      areas: [lastAreasValue],
      fields: [lastFieldsValue],
      initiatives: [lastInitiativesValue],
      geo_scopes: (_lastSearchQuery?.geo_scopes) ? _lastSearchQuery?.geo_scopes : [''], // cluster
      issuers: [lastIssuersValue],
      phases: (_lastSearchQuery?.phases) ? _lastSearchQuery?.phases : [''],
      processing_status: (_lastSearchQuery?.processing_status) ? _lastSearchQuery?.processing_status : [''], // tramitation status
      publication_date: builder.group({
        start_date: [lastStartDateValue],
        end_date: [lastEndDateValue],
      })
    });
  }

  private refreshTransformMasterDataValues(_lastSearchQuery: any, masterDataType: MasterDataTypes) {
    let masterData = this.getMasterData.transformFiltered(masterDataType);

    let lastDatasValue = [];
    if (_lastSearchQuery) {
      _lastSearchQuery.forEach(element => {
        if (typeof element != 'object') {
          let data = masterData.find(a => a.id == element);
          data.parentId = data.id;
          lastDatasValue.push(data);
        } else {
          lastDatasValue.push(element);
        }
      });
    }
    return lastDatasValue;
  }
  
  private refreshTransformMasterDataValuesByFilter(_lastSearchQuery: any, filterRelation: MasterDataTypes) {
    let lastDatasValue = [];
    let ids = this.masterDataService.getFilteredRelations("cluster", _lastSearchQuery?.geo_scopes, filterRelation);
    let masterData = this.refreshMasterDataValuesReturn(filterRelation, ids);
    
    if (_lastSearchQuery?.issuers) {
      _lastSearchQuery?.issuers.forEach(element => {
        if (typeof element != 'object') {
          let data = masterData.find(a => a.id == element);
          if (data) {
            data.parentId = data.id;
            lastDatasValue.push(data);
          }
        } else {
          lastDatasValue.push(element);
        }
      });
    }
    return lastDatasValue;
  }

  private refreshTransformMasterDataFields(_lastSearchQuery: any, filterRelation: MasterDataTypes) {
    let lastDatasValue = [];
    let idsn = [];
    let areas = this.getMasterData.transformFiltered(MasterDataTypes.AREA);
    areas.forEach(area => {
      idsn = [...this.masterDataService.getFilteredRelations("area", area.id, filterRelation), ...idsn];
    });
    let fields = this.refreshMasterDataValuesReturn(filterRelation, idsn);
    if (_lastSearchQuery) {
      _lastSearchQuery.forEach(element => {
        if (typeof element != 'object') {
          let materia = fields.find(a => a.id == element);
          let parentId = this.masterDataService.searchParent(filterRelation, materia.id, "area");
          materia.parentId = parentId;
          lastDatasValue.push(materia);
        } else {
          lastDatasValue.push(element);
        }
      });
    }
    return lastDatasValue;
  }
  
  ngOnInit(): void {
    const userName = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.aws.Auth.userPoolWebClientId}.LastAuthUser`)
    this.user_id= JSON.parse(localStorage.getItem(`CognitoIdentityServiceProvider.${environment.aws.Auth.userPoolWebClientId}.${userName}.userData`)).UserAttributes.find(a => a.Name == "sub")?.Value
    this.usersService.getUserAppData(this.user_id)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.user_interests = res.field_interest;
        if (this.user_interests?.length > 0) {
          const areas = this.user_interests.map(field => this.masterDataService.searchParent("field", field, "area"))
          this.options.areas = this.getMasterData.transformFiltered(MasterDataTypes.AREA).filter(area => areas.includes(area.id));
        } else {
          this.options.areas = this.getMasterData.transformFiltered(MasterDataTypes.AREA);

        }
      })

    this.options.lang = this.translateService.getLangs().map(e => e.toUpperCase());
    this.options.validated = ValidationStatus;
    this.options.initiatives = this.getMasterData.transformFiltered(MasterDataTypes.INITIATIVES);
    this.options.clusters = this.getMasterData.transformFiltered(MasterDataTypes.CLUSTER);
    this.options.phases = this.getMasterData.transformFiltered(MasterDataTypes.PHASE);
    this.options.processing_status = ProcessingStatus;

    this.searchForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      () => {
        this.formChanged.emit(this.searchForm);
      }
    );

    this.reset.pipe(untilDestroyed(this)).subscribe(() => {
      this.resetForm();
      this.resetValues.emit();
    });
  }

  readForm(event: any): void {
    if (event.target.name) {
      const parentKey = event.target.value;

      if (event.target.name === "geo_scopes") {
        const ids = this.masterDataService.getFilteredRelations("cluster", parentKey, "issuer");
        this.refreshMasterDataValues("issuers", "issuer", ids);
      }
    }
  }

  getUserData(): void {

    this.usersService.getUserDetail(this.userId).pipe(untilDestroyed(this)).subscribe((res) => {
      let role = Roles.find(role => res.user.groups.includes(role.name.replace('ROLES.','').toLowerCase()))
      this.showValidatedOptions = role.name != "ROLES.EXTERNAL"
    })
  }

  private refreshMasterDataValues(dest: any, masterdata_key: any, ids: any): void {
    const myData: Record<string, string>[] = [];
    if (ids && ids.length > 0) {
      ids.forEach((id: string) => {
        myData.push({
          id: id,
          name: this.getMasterdataElementName.transform(masterdata_key, id),
        });
      });
    }
    this.options[dest] = myData;
  }

  private refreshMasterDataValuesReturn(masterdata_key: any, ids: any) {
    const myData: Record<string, string>[] = [];
    if (ids && ids.length > 0) {
      ids.forEach((id: string) => {
        myData.push({
          id: id,
          name: this.getMasterdataElementName.transform(masterdata_key, id),
        });
      });
    }
    return myData;
  }

  setFieldValueByGroup(ev: any, group: any, control: any): void {
    (this.searchForm.controls[group] as UntypedFormGroup).controls[control].setValue(ev.target.value);
  }

  setFieldValue(ev: any, control: any): void {
    this.searchForm.controls[control].setValue(ev);
    if (control === "areas") {
      let ids = []
      if (this.user_interests?.length > 0) {
        ev.forEach(area => {
          ids = [...this.masterDataService.getFilteredRelations("area", area, "field"), ...ids]
            .filter(field => this.user_interests.includes(field))
        })
      }
      else {
        ev.forEach(area => {
          ids = [...this.masterDataService.getFilteredRelations("area", area, "field"), ...ids]
        })
      }

      this.refreshMasterDataValues("fields", "field", ids);
    }
  }


  resetForm(): void {
    this.options.fields = [];
    this.options.issuers = [];

    this.searchForm.reset({
      title: '',
      lang: '',
      validated: '', // text status ex: validated
      areas: [],
      fields: [],
      initiatives: [],
      geo_scopes: '', // cluster
      issuers: [],
      phases: '',
      processing_status: '', // tramitation status
      publication_date: {
        start_date: '',
        end_date: '',
      }
    });
    const elements = document.querySelectorAll(".masterdata-checkbox input[type='checkbox']");
    elements.forEach((elem: HTMLInputElement) => {
      elem.checked = false;
    });
  }

  changeGeoScope(): void {
    this.searchForm.value.issuers = [];
  }
}
