import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowRecordComponent } from './pages/shows/show-record/show-record.component';
import { ShowRuleComponent } from './pages/shows/show-rule/show-rule.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BreadcrumComponent } from './components/breadcrum/breadcrum.component';
import { ButtonComponent } from './components/button/button.component';
import { AlertComponent } from './components/items/alert/alert.component';
import { CompanyComponent } from './components/items/company/company.component';
import { RuleComponent } from './components/items/rule/rule.component';
import { UserComponent } from './components/items/user/user.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModalComponent } from './components/modal/modal.component';
import { NotificationComponent } from './components/notification/notification.component';
import { EditCompanyComponent } from './pages/edits/edit-company/edit-company.component';
import { EditRuleComponent } from './pages/edits/edit-rule/edit-rule.component';
import { EditUserComponent } from './pages/edits/edit-user/edit-user.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterAlertComponent } from './pages/registers/register-alert/register-alert.component';
import { RegisterCompanyComponent } from './pages/registers/register-company/register-company.component';
import { RegisterRuleComponent } from './pages/registers/register-rule/register-rule.component';
import { RegisterUserComponent } from './pages/registers/register-user/register-user.component';
import { AlertRecordComponent } from './pages/search/alert-record/alert-record.component';
import { SearchCompanyComponent } from './pages/search/search-company/search-company.component';
import { SearchFavouritesComponent } from './pages/search/search-favourites/search-favourites.component';
import { SearchRecordComponent } from './pages/search/search-record/search-record.component';
import { SearchUsersComponent } from './pages/search/search-users/search-users.component';
import { ShowAlertComponent } from './pages/shows/show-alert/show-alert.component';
import { ErrorViewComponent } from './components/error-view/error-view.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SearchPipe } from '../shared/pipes/search/search.pipe';
import { HomeSearchComponent } from './components/forms/home-search/home-search.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { UserFormComponent } from './components/forms/user-form/user-form.component';

// material
import { MatRadioModule as MatRadioModule } from '@angular/material/radio';
import { MatMenuModule as MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule as MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { CompanyFormComponent } from './components/forms/company-form/company-form.component';
import { EvolutionFilterComponent } from './components/evolution-filter/evolution-filter.component';
import { GetMasterDataPipe } from '../shared/pipes/get-masterdata/get-masterdata.pipe';
import { GetMasterDataElementPipe } from '../shared/pipes/get-masterdata-element/get-masterdata-element.pipe';
import { SearchAlertsComponent } from './components/forms/search-alerts/search-alerts.component';
import { MasterdataManagementComponent } from './pages/masterdata/masterdata-management/masterdata-management.component';
import { FooterComponent } from './components/footer/footer.component';
import { LegalNoticeComponent } from './pages/legal-notice/legal-notice.component';
import { LogoutComponent } from './pages/auth/logout/logout.component';
import { RuleFormComponent } from './components/forms/rule-form/rule-form.component';
import { NoAccessComponent } from './pages/auth/no-access/no-access.component';
import { ShowCompanyComponent } from './pages/shows/show-company/show-company.component';
import { ShowUserComponent } from './pages/shows/show-user/show-user.component';

@NgModule({
  declarations: [
    MenuComponent,
    ModalComponent,
    RegisterUserComponent,
    SearchUsersComponent,
    RegisterCompanyComponent,
    SearchCompanyComponent,
    SearchRecordComponent,
    SearchFavouritesComponent,
    RegisterAlertComponent,
    AlertRecordComponent,
    ShowRuleComponent,
    EditUserComponent,
    BreadcrumComponent,
    EditCompanyComponent,
    RuleComponent,
    ShowAlertComponent,
    AlertComponent,
    UserComponent,
    CompanyComponent,
    ShowRecordComponent,
    LoaderComponent,
    NotificationComponent,
    RegisterRuleComponent,
    EditRuleComponent,
    ButtonComponent,
    HomeComponent,
    ErrorViewComponent,
    LogoutComponent,
    NoAccessComponent,
    SearchPipe,
    HomeSearchComponent,
    UserFormComponent,
    MultiselectComponent,
    DatepickerComponent,
    CompanyFormComponent,
    EvolutionFilterComponent,
    GetMasterDataPipe,
    GetMasterDataElementPipe,
    SearchAlertsComponent,
    MasterdataManagementComponent,
    RuleFormComponent,
    FooterComponent,
    LegalNoticeComponent,
    ShowCompanyComponent,
    ShowUserComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    TranslateModule.forChild(),
    NgxPaginationModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatMenuModule,
    Ng2SearchPipeModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild(),
  ],
  exports: [
    MenuComponent,
    ModalComponent,
    RegisterUserComponent,
    SearchUsersComponent,
    RegisterCompanyComponent,
    SearchCompanyComponent,
    SearchRecordComponent,
    SearchFavouritesComponent,
    RegisterAlertComponent,
    AlertRecordComponent,
    ShowRuleComponent,
    EditUserComponent,
    BreadcrumComponent,
    EditCompanyComponent,
    RuleComponent,
    ShowAlertComponent,
    AlertComponent,
    UserComponent,
    CompanyComponent,
    ShowRecordComponent,
    LoaderComponent,
    NotificationComponent,
    RegisterRuleComponent,
    EditRuleComponent,
    ButtonComponent,
    HomeComponent,
    SearchPipe,
    EvolutionFilterComponent,
    GetMasterDataPipe,
    GetMasterDataElementPipe,
    FooterComponent,
  ]
})
export class CoreModule { }
