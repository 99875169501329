import { Component, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, Observable, of } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, shareReplay, startWith, tap } from "rxjs/operators";
import { CognitoUser, GroupRef } from "src/aws/services/cognito/cognito.model";
import { CognitoService } from "src/aws/services/cognito/cognito.service";
import { CompanyService } from 'src/app/shared/services/api/company/company.service';

type GroupControl = {
  key: string,
  source: Observable<CognitoUser[]>
}

type SortValue = {
  field: string,
  asc: boolean,
}

@UntilDestroy()
@Component({
  selector: "app-search-users",
  templateUrl: "./search-users.component.html",
  styleUrls: ["./search-users.component.scss"],
})
export class SearchUsersComponent implements OnInit {
  // Pagination
  page = 1;
  pageSize = 4;

  // Tipo de usuarios
  typeUser: "extern" | "intern" | "deleted" = "intern";

  groupsControl: UntypedFormArray = new UntypedFormArray([]);
  searchControl: UntypedFormControl = new UntypedFormControl(null);
  sortControl: UntypedFormControl = new UntypedFormControl({ field: 'email', asc: true });
  usersList: CognitoUser[] = [];

  usersWithoutGroup$: Observable<CognitoUser[]>;
  usersList$: Observable<CognitoUser[]>;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    localStorage.removeItem("lastSearchQuery");
    
    this.setupRx();
    this.loadData();
    this.cognitoService.refresh().subscribe()
  }

  setupRx() {
    this.usersWithoutGroup$ = this.cognitoService.getUsersWithoutGroup();

    const usersFromGroups: Observable<CognitoUser[]> = (this.groupsControl.valueChanges as Observable<GroupControl[]>).pipe(
      distinctUntilChanged((a, b) => a.map(c => c.key).join('') === b.map(c => c.key).join('')),
      tap(v => console.log('groups to show', v)),
      map(v => v.map(c => c.source)),
      mergeMap(sources => sources.length ? combineLatest(sources) : of([])),
      map(res => res.flat())
    )

    const searchObs: Observable<null | string> = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(v => v?.trim()),
      map(v => v?.length >= 1 ? v : null),
      map(v => v == '@' ? null : v),         // If it's just '@' it's a no filter
      distinctUntilChanged(),
      debounceTime(100),
    );

    const sortObs: Observable<SortValue> = this.sortControl.valueChanges.pipe(
      startWith(this.sortControl.value),
    );

    const filteredList = combineLatest([
      usersFromGroups,
      searchObs,
    ]).pipe(
      tap(v => console.log('Users filter', v)),
      map(([list, filter]) => filter ? list.filter(u => this.filterUser(u, filter)) : list),
      distinctUntilChanged((a, b) => {
        if (a.length != b.length) {
          return false;
        }

        return a.map(u => u.id).join('') == b.map(u => u.id).join('');
      })
    )

    const sortedList = combineLatest([
      filteredList,
      sortObs,
    ]).pipe(
      tap(v => console.log('Users sort', v)),
      map(([list, sort]) => {
        if (!sort) {
          return list;
        }
        return list.sort(userComparator(sort));
      }),
    );

    this.usersList$ = sortedList.pipe(
      shareReplay(),
    );

    // Make sure that the
    this.usersList$.pipe(
      untilDestroyed(this),
      map(list => list.length),
      distinctUntilChanged(),
      filter(v => this.page * this.pageSize > v),
      tap(v => {
        this.page = Math.ceil(v / this.pageSize);
      })
    ).subscribe();
  }

  loadData() {
    this.cognitoService.getGroups().pipe(
      untilDestroyed(this),
      tap(groups => this.setupGroupsControl(groups)),
    ).subscribe()
  }

  setupGroupsControl(groups: GroupRef[]) {
    this.groupsControl.disable({ onlySelf: true, emitEvent: false });

    // Remove exisint ones
    while (this.groupsControl.controls.length) {
      this.groupsControl.removeAt(0, { emitEvent: false })
    }

    if (groups.length) {
      const controlBuilder = (key: string, source: Observable<CognitoUser[]>) => {
        return new UntypedFormControl({
          key,
          source: source
        })
      }

      this.groupsControl.push(controlBuilder('noGroup', this.usersWithoutGroup$), { emitEvent: false });
      groups.forEach(g => {
        this.groupsControl.push(controlBuilder(g, this.cognitoService.getUsersInGroup(g)), { emitEvent: false });
      })
    }

    this.groupsControl.enable();
    console.log('Setted groups', this.groupsControl.controls.map(c => c.value.key));
  }


  switchGroup(control: AbstractControl) {
    if (control.disabled) {
      control.enable();
    } else {
      control.disable();
    }
  }

  private filterUser(user: CognitoUser, filter: string): boolean {
    const lowercaseFilter = filter.toLowerCase();

    return (
        user.attributes.email?.toLowerCase()?.includes(lowercaseFilter) ||
        user.attributes.name?.toLowerCase()?.includes(lowercaseFilter) ||
        user.attributes.familyName?.toLowerCase()?.includes(lowercaseFilter) ||
        this.findRole(user.groups, lowercaseFilter) ||
        this.getCompanySync(user.company, lowercaseFilter)
    );
  }

  private findRole(groups: string[], pattern: string): boolean {
      const regex = new RegExp(pattern, 'i'); // 'i' para búsqueda insensible a mayúsculas/minúsculas
      return groups.some(group => regex.test(group));
  }

  private companyCache: { [companyId: string]: string } = {};

  private getCompanySync(companyId: string, filter: string): boolean {
      if (!companyId) return false;

      // Si la empresa ya está en caché
      const companyName = this.companyCache[companyId];
      if (companyName) {
          return companyName.toLowerCase().includes(filter.toLowerCase());
      }

      // Si no está en caché, cargarla y guardarla
      this.companyService.getCompany(companyId).pipe(untilDestroyed(this)).subscribe({
          next: (res) => {
              this.companyCache[companyId] = res.name; // Almacena en caché
          },
          error: (err) => {
              console.error(`Error fetching company data for ID ${companyId}:`, err);
          },
      });

      // Mientras se carga, devuelve false
      return false;
  }

  trackUser(index: number, user: CognitoUser): string {
    return user.id;
  }

  showUser(user: CognitoUser): void {
    this.router.navigate(["administration", "users", user.id], { state: { id: user.id } });
  }
}


function userComparator(sort: SortValue) {
  const order = (sort.asc ? 1 : -1);
  const getter = (u: CognitoUser) => u.attributes[sort.field];

  return (a: CognitoUser, b: CognitoUser) => {
    return (getter(a)?.localeCompare(getter(b)) || 1) * order;
  }
}
