export const environment = {
  production: true,
  hmr: false,
  selfUrl: "https://regulatoryspace-dev.deloitte.es/",
  apiUrl: "//api/",
  masterDataUrl: null,

  aws: {
    region: 'eu-central-1',
    LOG_LEVEL: 'INFO',
    localStack: false,
    Auth: {
      autoLogin: false,
      endpoint: undefined,
      identityPoolId: "eu-central-1:c27990a2-23d1-472e-8d4c-b7bc6952e2c8", // REQUIRED - Amazon Cognito Identity Pool ID
      region: "eu-central-1", // REQUIRED - Amazon Cognito Region
      userPoolId: "eu-central-1_SuSAR61pw", // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: "b9dpijqj54p8dgcn5oup7r50u", // OPTIONAL - Amazon Cognito Web Client ID,
      oauth: {
        domain: "rspace-dev.auth.eu-central-1.amazoncognito.com",
        scope: [
          "phone",
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: 'https://regulatoryspace-dev.deloitte.es/',
        responseType: 'code',
        redirectSignOut: "https://regulatoryspace-dev.deloitte.es/logout/",
        federatedSignIn: {
          customProvider: undefined, //'rspace-idp-deloitte'
        }
      },
    },
    API: {
      interceptHttp: true,
      revertOnError: false,
      endpoints: [
        {
          name: "RspaceAPI",
          endpoint: "https://fzvwgzs8sh.execute-api.eu-central-1.amazonaws.com"
        }
      ]
    },
    Storage: {
      customPrefix: {
        public: ''
      }
    },
    S3: {
      region: "eu-central-1",
      Bucket: "rspacecdkstack-databuckete3889a50-18vsjo0zk9k5f",
      forcePathStyle: true,
      endpoint: '',
      paths: {
        FILE_UPLOAD: 'file-upload/',
        APP_CONFIG: 'app-config/',
      }
    }
  },

  defaultLang: 'es',
  appLangs: [
    "es", "en", "pt"
  ]
};
